.nav-container{
    display: flex;
    justify-content: space-between;
    background-color: brown;
    color: white;
    min-height: 6vh;
    /* margin-top: 10px; */
    width: 1464px;
    /* position:fixed; */
    z-index: 1;
    /* margin-left: 15px; */
    /* border-top-left-radius: 50px;
    border-bottom-left-radius: 50px; */
    /* border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; */
}
.nav-container ul{
    display: inline-flex;
    list-style-type: none;
    margin: 0;
    margin-left: 60px;
    padding: 0;
}

.nav-container li{
    padding: 10px 15px;
    font-size: 16px;
    position: relative;
    line-height: 23px;
    font-family: 'Montserrat', sans-serif;
}
.nav-container li:after{
    position: absolute;
    height: 40%;
    width: 1px;
    border-right:solid 1px #ffffff;
    content: '';
    top: 30%;
    right: 0;
}
.nav-container li a{
    text-decoration: none;
    color: inherit;
}
.dept{
    width: 100%;
    border-right: 1px solid brown;
}
.last{
    border: none;
    margin-left: 30px;
}
.expandable:hover .sub-menu{
    display: block;
}
.menu-flex{
    display: flex;
}
.sub-menu{
    display:none;
    position: absolute;
    background-color: #fff;
    border: 2px solid brown;
    min-width: 550px;
    z-index: 1;
    position: absolute;
    top: 100%;
    padding: 10px;
    padding-top: 0px;
    color: brown;
    filter: brightness(100%);
}
.sub-menu h4{
    background-color: brown;
    color: #fff;
    padding: 10px;
}
.sub-menu-link {
    display: block;
    padding: 10px;
    color: #e25252;
    font-size: 16px;
  }
  
  .sub-menu-link:hover {
    color: white;
    background-color: #e25252;
  }