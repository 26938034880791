.drawer {
    overflow: hidden;
    max-height: 0;
    transition: max-height ease-out;
  }
  
  .drawer.open {
    max-height: 100%; /* Adjust this value based on your content's size */
    transition: max-height 0.5s ease-in;
    border: 1px solid #ccc;
    padding: 5px;
  }
  .form {
    position:sticky;
    top:0;
    overflow: hidden;
    max-height: 0;
    transition: max-height ease-out;
  }
  
  .form.show {
    max-height: 200px; /* Adjust this value based on your content's size */
    transition: max-height 0.5s ease-in;
  }
  