/* .container{
    padding: 20px;
    margin-left: 10px;
    padding-top: 5px;
}
.container-content .sideBar-content{
    flex: 1;
}
.container-content{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.container-content .about-content{
   width:fit-content;
   flex: 1;
}
.container-content .about-content button{
    height:40px;
    width: 90px;
    background-color: brown;
    color: white;
    cursor: pointer;
    text-decoration: none;
 }
 .flex-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 }
 .flex-container-content{
    width: 33%;
    text-align: center;
    margin-top: 30px;
 } */
 /* .flex-container .flex-container-content img{
  height: 175px; 
  width: 100%; 
  margin-bottom: 10px; 
 } */

 .container {
    padding: 20px;
    margin-left: 10px;
    padding-top: 5px;/* Limit container width */
}

.container-faculty-content {
    margin-top: 30px;
    display: flex;
   
}

.about-faculty-content {
    width: 100%;
}

.about-faculty-content button {
    padding: 10px;
    background-color: brown;
    color: white;
    cursor: pointer;
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add some border radius for button */
}

.flex-container {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 16px;
    gap: 80px;
    flex-wrap: wrap;
}

.flex-container-content {
     /* Include padding and border in width calculation */
     text-align: left;
     width: 200px;
     margin-left: 20px;
}

.flex-container-content img {
/* Ensure images don't overflow container */
    height: 150px; /* Maintain aspect ratio */
    width: 150px;
    margin-bottom: 10px;
    border-radius: 5px; /* Add some border radius for images */
    cursor: pointer;
}
.flex-container-content h4, .flex-container-content p{
margin: 0 !important;
}

