.container{
    padding: 20px;
    margin-left: 10px;
    padding-top: 5px;/* Limit container width */
}
.container-content{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap; /* Allow content to wrap */
    justify-content: space-between;
}

.container-content .profile-content{
    flex: 1.5;
    border: 1px solid #ccc;
    padding: 12px;
    height: 100%;
}
.container-content .profile-content .profile-content-header{
    background-color: brown;
    color: white;
    padding-left: 5px;
}

.about-content{
    flex: 0.5;
     /* Set a minimum width for content */
}
.userImage{
    margin-top: 10%;
    width: 100%;
    height: 100%;
}
.userImage img{
    width: 300px;
    height:300px;
    border-radius: 50%;
    border : 1px solid brown;
    box-shadow: 0 0 10px brown;
}