.container-electrical{
    padding: 20px;
    margin-left: 10px;
    padding-top: 5px;
}
/* .container-electrical p{
    font-size: 25px;
    color: #fff;
    font-family: 'Lobster', sans-serif;
    font-weight: bold;
    text-align: center;
    border: 1px solid brown;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: brown;
    padding: 5px;
} */
.container-electrical .container-ee{
    margin-top: 30px;
    width: 100% !important;
}
.container-electrical .container-ee .department-content h1{
  background-color: brown;
  font-size: 20px;
  padding:5px;
  color: #fff;
  font-weight: 400;
  width: 1454px;
}
.container-electrical .container-ee .department-content .container-btn button{
    background-color: brown;
    border:none;
    color: #fff;
    font-size: 14px;
    border: 1px solid black;
    padding: 10px;
    cursor: pointer;
}
.container-electrical .container-ee .department-content .container-btn button:hover{
    background-color: rgb(172, 7, 7);
}
.container-electrical .container-ee .department-content .app-slider{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.container-electrical .container-ee .about-dept{
    line-height: 2em;
    font-size: 18px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


