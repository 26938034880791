.container-header{
    min-height: 200px;
    z-index: 0;
    display: flex;
    width: calc(100% - 5px);
}
.container-header img{
    height: 100px;
    margin-top: 30px;
    margin-left: 30px;
}
.container-header .container-header-text{
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    color: brown;
    margin-top: 65px;
    margin-left: 40px;
    font-weight: bold;
}
