.related-links{
    border: 1px solid brown;
    width: 40%;
    padding: 10px;
    background-color: #FAF3DD;
    float: right;
    margin-top: 40px;
}
.related-links h2{
    background-color: brown;
    padding: 5px;
    padding-left: 15px;
    color: white;
}
.related-links ul{
    list-style: none;
    line-height: 3em;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: left;
    padding-left: 0px !important;
}
.related-links ul li{
    color: brown;
    text-align: left;
    border-bottom:1px solid brown;
    padding-left: 5px;
}
.related-links ul li:last-child{
    border-bottom:none;
}
.related-links ul li:hover{
    background-color: brown;
    color: #fff;
    cursor:pointer;
}
.sideBar-link{
    text-decoration: none;
    color: inherit;
}
.sideBar-link:hover{
    color: inherit;
    cursor:pointer;
}
