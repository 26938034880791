.container{
    padding: 20px;
    margin-left: 10px;
    padding-top: 5px;
}
.home-about{
    /* border-bottom: solid 1px brown; */
    display: flex;
}
.about-us-content h2{
    color: #ffffff;
    background-color: brown;
    padding-left: 10px;
}
.about-us-content{
    width: 50%;
    line-height: 2;
}
.dir-img{
    margin-top: 20px;
    margin-left: 200px;
    text-align: center;
}
.dir-img img{
    height: 250px;
    width: 250px;
}

