.container{
    padding: 20px;
    margin-left: 10px;
    padding-top: 5px;
}
.form-container{
    margin-top: 80px;
    margin-left: 10%;
}
.form-container .form-input label{
    display: inline-block;
    width: 10%;
}
.form-container .form-input input{
    height: 30px;
    width: 20%;
    padding: 5px;
    margin-top: 20px;
 }
 .form-container .form-btn{
    display: flex;
    gap:10px;
    margin-top: 1em;
 }
.form-container .form-btn button{
    width: 90px;
    height: 40px;
    font-size: 14px;
    background-color: brown;
    color: #fff;
    cursor: pointer;
 }