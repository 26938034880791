.login-container{
    border: 1px solid rgb(0, 149, 255);
    position: absolute;
    top: 10%;
    left: 37%;
    width: 25%;
    height: 60%;
    border-radius: 25px;
    box-shadow: 0 0 5px rgb(0, 149, 255);
    background-image: url('../../images/loginback.jpg');
    background-size: cover; /* Cover the entire page */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    z-index: 1;
    vertical-align: middle;
}
.login-container.login-content, .login-container.login-content-username{
    text-align: center;
    margin-top: 30px;
}
.login-container .login-content-username{
    margin-top: 26px;
}

.login-container input, .login-container select{
    height: 40px;
    width: 250px;
    border-radius: 50px;
    padding: 2px;
    padding-left: 10px;
    border: 2px solid black;
    font-family:'Montserrat';
    background-color: #fff !important;
}
.login-container button{
    width: 200px;
    height: 30px;
    border-radius: 25px;
    border: 2px solid #ccc;
    background-color: brown;
    color: #fff;
    padding: 5px;
    font-family:'Montserrat';
    box-shadow: 0 0 10px brown;
    cursor: pointer;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust color and opacity */
    z-index: 0;
    filter: brightness(60%);
}
